import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { ContactBlock } from '../../components/ContactBlock/ContactBlock'
import { ContactCard } from '../../components/ContactCard/ContactCard'
import { ContactForm } from '../../components/ContactForm/ContactForm'
import { Button } from '../../components/Button/Button'
import { PhoneIconLarge, HomeIcon, EmailIconLarge } from '../../assets/images'
import './ContactsPage.scss'


export const ContactsPage = memo(() => {
  const [t] = useTranslation('global')
  const navigate = useNavigate()

  return (
    <div className="contacts-page-wrapper">
      <section className="contacts-page-banner">
        <div className="contacts-page-banner--content">
          <div className="contacts-page-banner--content-desc">
            <h1 className="contacts-page-banner--content-desc-title">
              {t('contactsPage.Banner.title')}
            </h1>
            <div className="contacts-page-banner--content-desc-listItem">
              <img src={HomeIcon} alt="PhoneIcon" />
              +90 542 691 35 45
            </div>
            <div className="contacts-page-banner--content-desc-listItem">
              <img src={PhoneIconLarge} alt="PhoneIcon" />
              {t('contactsPage.Banner.address')}
            </div>
            <div className="contacts-page-banner--content-desc-listItem">
              <img src={EmailIconLarge} alt="PhoneIcon" />
              sdbitkisel@gmail.com
            </div>
            <Button
              onClick={() => navigate('/purchase')}
            >{t('contactsPage.Banner.button')}</Button>
          </div>
          <ContactForm />
        </div>
      </section>
      <section className="contacts-page-contacts">
        <div className="contacts-page-contacts--purchase">
          <h4 className="title">
            {t('contactsPage.contacts.title')}
            <span className="title title_yellow">
              {' '}
              {t('contactsPage.contacts.titleYellow')}
            </span>
          </h4>
          <p className="text text_contact">{t('contactsPage.contacts.text')}</p>
          <div className="contacts-page-contacts--purchase--cardsContainer">
            <div className="contacts-page-contacts--purchase--cards">
                <ContactCard
                  title='Administrator'
                  name='Deniz Selmanov'
                  phone='+90 542 691 35 45'
                  email='sdbitkisel@gmail.com'
                />
            </div>
          </div>
        </div>
        <ContactBlock
          title={t('contactsPage.contact.title')}
          titleYellow={t('contactsPage.contact.titleYellow')}
        />
      </section>
    </div>
  )
})
