import { ButtonHTMLAttributes, memo, ReactNode } from 'react'
import './Button.scss'

export enum ButtonSize {
  S = 'size_s',
  M = 'size_m',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  size?: ButtonSize
  disabled?: boolean
  children?: ReactNode
}

export const DarkButton = memo((props: ButtonProps) => {
  const {
    className,
    children,
    size = ButtonSize.M,
    disabled,
    ...otherProps
  } = props

  return (
    <button
      type="button"
      className={`darkButton ${className} ${size}`}
      disabled={disabled}
      {...otherProps}
    >
      {children}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size === ButtonSize.M ? '55' : '45'}
        height={size === ButtonSize.M ? '55' : '45'}
        viewBox="0 0 55 55"
        fill="none"
      >
        <path
          d="M17.9072 36.5928L36.5929 17.9071M36.5929 17.9071L36.5929 32.5443M36.5929 17.9071L21.9558 17.9071"
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    </button>
  )
})
