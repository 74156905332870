import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneIcon, EmailIcon } from '../../assets'
import './ContactBlock.scss'

type Props = {
  title: string
  titleYellow: string
}

export const ContactBlock = memo(({ title, titleYellow }: Props) => {
  const [t] = useTranslation('global')
  return (
    <div className="contact-block-wrapper">
      <div className="contact-block-desc">
        <h4 style={{color: 'white'}} className="title">
          {title}
          <span className="title title_yellow"> {titleYellow}</span>
        </h4>
        <p style={{color: 'white'}} className="text text_contact">{t('contactBlock.text')}</p>
        <div className="main-manager-block">
          <div className="manager-title manager-title_main">
            {t('contactBlock.label')}{' '}
            <span className="manager-title manager-title_main manager-title_yellow manager-title_yellow_main">
              Deniz Selmanov
            </span>
          </div>
          <div className="manager-contacts manager-contacts_main">
            <div className="manager-contacts--item manager-contacts--item_main">
              <img src={PhoneIcon} alt="phone" />
              +90 542 691 35 45
            </div>
            <div className="manager-contacts--item manager-contacts--item_main">
              <img src={EmailIcon} alt="email" />
              sdbitkisel@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
