import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { sinTech, BitkiselLogo } from '../../assets'
import './Footer.scss'

export default function Footer() {
  const { t } = useTranslation('global')

  return (
    <footer className="footer-wrapper">
      <div className="footer-content">
        <div className="footer-content--column-desc">
          <Link to="/">
            <img
              className="footer-content--column-desc-logo"
              src={BitkiselLogo}
              alt="logo"
            />
          </Link>
          <div className="footer-content--column-desc-text">
            {t('footer.mission')}
          </div>
        </div>

        <div className="footer-content--column-nav">
          <div className="footer-content--column">
            <div className="footer-content--column-title">
              {t('footer.info')}
            </div>
            <div className="footer-content--column-nav-links">
              <Link
                className="footer-content--column-nav-links-link"
                to="/about"
              >
                {t('header.about')}
              </Link>
              <Link
                className="footer-content--column-nav-links-link"
                to="/contacts"
              >
                {t('footer.contacts')}
              </Link>
              <Link className="footer-content--column-nav-links-link" to="#">
                {t('footer.faq')}
              </Link>
            </div>
          </div>
          <div className="footer-content--column">
            <div className="footer-content--column-title">
              {t('footer.realisation')}
            </div>
            <div className="footer-content--column-nav-links">
              <Link
                className="footer-content--column-nav-links-link"
                to="/export"
              >
                {t('header.export')}
              </Link>
            </div>
          </div>
        </div>

        <div className="footer-content--column">
          <div className="footer-content--column-title">
            {t('footer.contacts')}
          </div>
          <div className="footer-content--column-links">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="21"
                viewBox="0 0 15 21"
                fill="none"
              >
                <path
                  d="M7.50124 0C3.35825 0 0 3.34107 0 7.46163C0 9.20803 0.593134 10.5559 1.96063 12.5938C3.327 14.6317 7.50124 20.2174 7.50124 20.2174C7.50124 20.2174 11.6756 14.633 13.0394 12.5938C14.408 10.557 15 9.20803 15 7.46163C15.0002 3.34107 11.6419 0 7.50124 0ZM7.50124 10.2828C5.93521 10.2828 4.66573 9.01977 4.66573 7.46176C4.66573 5.906 5.93521 4.64075 7.50124 4.64075C9.06726 4.64075 10.3367 5.90595 10.3367 7.46176C10.3367 9.01977 9.06726 10.2828 7.50124 10.2828Z"
                  fill="#FFA500"
                />
              </svg>
              <a className="footer-content--column-links-link" href="">
                {t('footer.address')}
              </a>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M13.6851 11.9076C13.097 11.327 12.3628 11.327 11.7785 11.9076C11.3328 12.3496 10.887 12.7916 10.4488 13.2411C10.3289 13.3647 10.2278 13.391 10.0817 13.3085C9.79325 13.1512 9.4861 13.0239 9.20891 12.8516C7.91662 12.0387 6.83409 10.9937 5.87518 9.81749C5.39946 9.23315 4.97619 8.6076 4.68027 7.9034C4.62034 7.76106 4.63158 7.66742 4.7477 7.5513C5.19345 7.12053 5.62795 6.67853 6.06621 6.23653C6.67677 5.62223 6.67677 4.90304 6.06246 4.28499C5.71411 3.93288 5.36575 3.58827 5.01739 3.23617C4.6578 2.87658 4.30195 2.51324 3.93861 2.15739C3.35053 1.58428 2.61636 1.58428 2.03202 2.16113C1.58252 2.60313 1.15176 3.05637 0.694775 3.49088C0.271503 3.89168 0.0579944 4.38238 0.0130451 4.95548C-0.0581245 5.88818 0.170367 6.76843 0.492504 7.62621C1.15176 9.40171 2.15563 10.9787 3.373 12.4245C5.01739 14.3798 6.98018 15.9268 9.27633 17.0431C10.3102 17.545 11.3815 17.9308 12.5464 17.9945C13.348 18.0395 14.0447 17.8372 14.6028 17.2116C14.9849 16.7846 15.4157 16.3951 15.8202 15.9868C16.4195 15.38 16.4233 14.6458 15.8277 14.0465C15.116 13.331 14.4005 12.6193 13.6851 11.9076Z"
                  fill="#FFA500"
                />
                <path
                  d="M12.9696 8.92219L14.3518 8.68621C14.1345 7.41639 13.5352 6.26644 12.625 5.35247C11.6623 4.38981 10.4449 3.78299 9.10396 3.5957L8.90918 4.98538C9.94676 5.13147 10.8907 5.59969 11.6361 6.3451C12.3403 7.0493 12.801 7.9408 12.9696 8.92219Z"
                  fill="#FFA500"
                />
                <path
                  d="M15.131 2.91421C13.5353 1.31851 11.5163 0.310899 9.28755 0L9.09277 1.38968C11.0181 1.65938 12.7636 2.53214 14.1421 3.90684C15.4493 5.21411 16.3071 6.866 16.618 8.6827L18.0002 8.44671C17.6369 6.34159 16.6442 4.43125 15.131 2.91421Z"
                  fill="#FFA500"
                />
              </svg>
              <a
                className="footer-content--column-links-link"
                href="tel: +90 542 691 35 45"
              >
                +90 542 691 35 45
              </a>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  d="M12.9596 8.83008L11.2845 10.5108C10.6052 11.1925 9.41003 11.2072 8.71606 10.5108L7.04088 8.83008L1.02441 14.8661C1.24837 14.9696 1.49529 15.0314 1.7578 15.0314H18.2428C18.5053 15.0314 18.7521 14.9697 18.976 14.8661L12.9596 8.83008Z"
                  fill="#FFA500"
                />
                <path
                  d="M18.2427 0.968262H1.75772C1.49521 0.968262 1.24829 1.03006 1.02441 1.13362L7.45344 7.58397C7.45387 7.5844 7.45437 7.58448 7.4548 7.58491C7.45523 7.58534 7.45531 7.58592 7.45531 7.58592L9.54574 9.68326C9.76778 9.9053 10.2327 9.9053 10.4548 9.68326L12.5448 7.58627C12.5448 7.58627 12.5453 7.58534 12.5457 7.58491C12.5457 7.58491 12.5466 7.5844 12.5471 7.58397L18.9759 1.13358C18.752 1.02998 18.5052 0.968262 18.2427 0.968262Z"
                  fill="#FFA500"
                />
                <path
                  d="M0.18696 1.95312C0.0710963 2.18743 0 2.44752 0 2.72612V13.2734C0 13.552 0.0710182 13.8121 0.186921 14.0464L6.21382 7.99994L0.18696 1.95312Z"
                  fill="#FFA500"
                />
                <path
                  d="M19.813 1.95312L13.7861 8.00002L19.813 14.0465C19.9288 13.8122 19.9999 13.5521 19.9999 13.2735V2.7262C19.9999 2.44752 19.9288 2.18743 19.813 1.95312Z"
                  fill="#FFA500"
                />
              </svg>
              <a
                className="footer-content--column-links-link"
                href="mailto: sdbitkisel@gmail.com"
              >
                sdbitkisel@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="powered-by">
          © SD-BITKISEL, Inc. 2024
          <a
            href="https://sintech.dev/"
            target="_blank"
            className="powered-by"
            rel="noreferrer"
          >
            <img src={sinTech} alt="sintech" />
          </a>
        </div>
        <div className="policy">
          <Link className="policy-link" to="">
            {t('footer.terms')}
          </Link>
          <Link className="policy-link" to="">
            {t('footer.privacy')}
          </Link>
        </div>
      </div>
    </footer>
  )
}
