import React, { memo } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import './MainPage.scss'
import { Button, ButtonSize } from '../../components/Button/Button'
import { Accordion } from '../../components/Accordions/AccordionMain/Accordion'
import {
  BitkiselEllipse,
  Ellipse2,
  GrainIcon,
  Corn,
  Barley,
  Grain,
  SunFlower,
  bitkisel4ikWebm,
  BitkiselFormLogo,
  SDB,
  bitkisel4ik,
  mainPageAboutSectionCardImage,
  mainPageAboutSectionCardImage2
} from '../../assets'
import CorpsCard from '../../components/CorpsCard/CorpsCard'
import { CorpSlider } from '../../components/Sliders/CorpSlider/CorpSlider'
import { ContactForm } from '../../components/ContactForm/ContactForm'
import { DarkButton } from '../../components/Button/DarkButton'

const corps = [
  {
    name: 'mainPage.productsCardName1',
    src: Grain,
    characteristics: {
      characteristic1: 'mainPage.productsCardDesc1/1',
      characteristic2: 'mainPage.productsCardDesc1/2',
    },
  },
  {
    name: 'mainPage.productsCardName2',
    src: SunFlower,
    characteristics: {
      characteristic1: 'mainPage.productsCardDesc2/1',
      characteristic2: 'mainPage.productsCardDesc2/2',
    },
  },
  {
    name: 'mainPage.productsCardName3',
    src: Corn,
    characteristics: {
      characteristic1: 'mainPage.productsCardDesc3/1',
      characteristic2: 'mainPage.productsCardDesc3/2',
    },
  },
  {
    name: 'mainPage.productsCardName4',
    src: Barley,
    characteristics: {
      characteristic1: 'mainPage.productsCardDesc4/1',
      characteristic2: 'mainPage.productsCardDesc4/2',
    },
  },
]

export const MainPage = memo(() => {
  const [t] = useTranslation('global')
  const navigate = useNavigate()

  const AccordionContent = [
    {
      question: t('mainPage.accordionQuestion'),
      response: t('mainPage.accordionResponse'),
    },
    {
      question: t('mainPage.accordionQuestion12'),
      response: t('mainPage.accordionResponse1'),
    },
    {
      question: t('mainPage.accordionQuestion1'),
      response: t('mainPage.accordionResponse2'),
    },
    {
      question: t('mainPage.accordionQuestion2'),
      response: t('mainPage.accordionResponse3'),
    },
    {
      question: t('mainPage.accordionQuestion3'),
      response: t('mainPage.accordionResponse4'),
    },
    {
      question: t('mainPage.accordionQuestion4'),
      response: t('mainPage.accordionResponse5'),
    },
  ]

  return (
    <div className="main-page-wrapper">
      <section className="main-page-banner">
        <video autoPlay muted loop playsInline id="myVideo" className="video">
          <source src={bitkisel4ikWebm} type="video/webm" />
          <source src={bitkisel4ik} type="video/mp4" />
        </video>
        <h1 className="main-page-banner--title">{t('mainPage.title')}</h1>
        <Button onClick={() => navigate('/about')}>
          {t('mainPage.bannerBtn')}
        </Button>
        <div className="main-page-banner--info">
          <div className="main-page-banner--info--column">
            <span className="main-page-banner--info--number">01</span>
            <h4 className="main-page-banner--info--title">
              {t('mainPage.bannerCulture')}
            </h4>
            <span className="main-page-banner--info--desc">
              {t('mainPage.bannerCultureDesc')}
            </span>
          </div>
          <div className="main-page-banner--info--column">
            <span className="main-page-banner--info--number">02</span>
            <h4 className="main-page-banner--info--title">
              {t('mainPage.bannerLogistic')}
            </h4>
            <span className="main-page-banner--info--desc">
              {t('mainPage.bannerLogisticDesc')}
            </span>
          </div>
          <div className="main-page-banner--info--column">
            <span className="main-page-banner--info--number">03</span>
            <h4 className="main-page-banner--info--title">
              {t('mainPage.bannerStorage')}
            </h4>
            <span className="main-page-banner--info--desc">
              {t('mainPage.bannerStorageDesc')}
            </span>
          </div>
        </div>
      </section>
      <section className="main-page-about">
        <img className="ellipse" src={BitkiselEllipse} alt="ellipse" />
        <img className="ellipse2" src={Ellipse2} alt="ellipse" />
        <div className="main-page-about--company">
          <div className="main-page-about--company--desc">
            <h2 className="main-page-about--company--desc--title">
              {t('mainPage.aboutCompanyTitle')}
            </h2>
            <p className="main-page-about--company--desc--text">
              {t('mainPage.aboutCompanyText')}
            </p>
            <div className="main-page-about--company--desc--advantages-list">
              <div className="main-page-about--company--desc--advantages-list--item">
                <img
                  className="main-page-about--company--desc--advantages-list--item-img"
                  src={GrainIcon}
                  alt="grain"
                />
                <span className="main-page-about--company--desc--advantages-list--item-text">
                  {t('mainPage.aboutCompanyAdvantages1')}
                </span>
              </div>
              <div className="main-page-about--company--desc--advantages-list--item">
                <img
                  className="main-page-about--company--desc--advantages-list--item-img"
                  src={GrainIcon}
                  alt="grain"
                />
                <span className="main-page-about--company--desc--advantages-list--item-text">
                  {t('mainPage.aboutCompanyAdvantages2')}
                </span>
              </div>
              <div className="main-page-about--company--desc--advantages-list--item">
                <img
                  className="main-page-about--company--desc--advantages-list--item-img"
                  src={GrainIcon}
                  alt="grain"
                />
                <span className="main-page-about--company--desc--advantages-list--item-text">
                  {t('mainPage.aboutCompanyAdvantages3')}
                </span>
              </div>
            </div>
            <DarkButton onClick={() => navigate('/about')}>
              {t('mainPage.aboutCompanyMoreBtn')}
            </DarkButton>
          </div>
          <img
            className="main-page-about--company--img"
            src={SDB}
            alt="aboutCompany"
          />
        </div>
        <div className="main-page-about--cards">
          <div className="main-page-about--card">
            <h4 className="main-page-about--card--title">
              <span>{t('mainPage.aboutCompanyPurchaseTitleOrange')}</span>{' '}
              {t('mainPage.aboutCompanyPurchaseTitle')}
            </h4>
            <img
              className="main-page-about--card--img"
              src={mainPageAboutSectionCardImage}
              alt="purchase"
            />
            <p className="main-page-about--card--text">
              {t('mainPage.aboutCompanyPurchaseText')}
            </p>
            <DarkButton onClick={() => navigate('/contacts')} size={ButtonSize.S}>
              {t('mainPage.aboutCompanyPurchaseBtn')}
            </DarkButton>
          </div>
          <div className="main-page-about--card">
            <h4 className="main-page-about--card--title">
              <span>{t('mainPage.aboutCompanySaleTitleOrange')}</span>{' '}
              {t('mainPage.aboutCompanySaleTitle')}
            </h4>
            <img
              className="main-page-about--card--img"
              src={mainPageAboutSectionCardImage2}
              alt="purchase"
            />
            <p className="main-page-about--card--text">
              {t('mainPage.aboutCompanySaleText')}
            </p>
            <DarkButton onClick={() => navigate('/contacts')} size={ButtonSize.S}>
              {t('mainPage.aboutCompanySaleBtn')}
            </DarkButton>
          </div>
        </div>
      </section>
      <section className="main-page-products">
        <div className="main-page-products--head">
          <div className="main-page-products--head--text">
            <div className="main-page-products--head--text--title">
              <h3 className="main-page-products--head--text--title_white">
                {t('mainPage.productsTitleWhite')}
              </h3>
              <h3 className="main-page-products--head--text--title_orange">
                {t('mainPage.productsTitleOrange')}
              </h3>
            </div>
            <div className="main-page-products--head--text--dash" />
            <span className="main-page-products--head--text--desc">
              {t('mainPage.productsTitleDesc')}
            </span>
          </div>
        </div>
        <div className="main-page-products--cards">
          {corps.map((corp) => (
            <CorpsCard
              name={corp.name}
              src={corp.src}
              characteristics={corp.characteristics}
              key={corp.name}
            />
          ))}
        </div>
        <CorpSlider corps={corps} />
      </section>

      <section className="contacts-page-banner">
        <div className="contacts-page-banner--content">
          <img
            className="contacts-page-banner--content-logo"
            src={BitkiselFormLogo}
            alt="logo"
          />
          <ContactForm />
        </div>
      </section>
      <section className="main-page-accordion">
        <div className="main-page-accordion--head">
          <div className="main-page-accordion--head--title">FAQ</div>
        </div>
        <Accordion AccordionContent={AccordionContent} />
      </section>
    </div>
  )
})

MainPage.displayName = 'MainPage'
